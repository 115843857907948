/* contact.css */
.contact-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
   
}

.contact-card {
    margin-bottom: 20px;
    flex: 0 0 calc(35% - 20px); 
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 49px 3px #0000000D;
    text-align: center;
    border-radius: 4px;
  
}
.contact-card h2 {
    font-size: 24px;
    margin-bottom: 5px;
    color: #0D113E;
    font-weight: 600;
    font-style: normal;
    font-family: 'HumeGeo', sans-serif;
}
.contact-card p {
    font-size: 16px;
    color: #6B7280;
    
}
ul {
    list-style: none;
    padding: 0;
}
ul li {
    margin-bottom: 5px;
}
.contact-text-contact{
    text-align: start;
    margin-left: 20px;
}

/* Add this CSS to your stylesheet */
.contact-container-1{
    /* display: flex; */
    flex-direction: column;
    align-items: start;
    text-align: start;
    padding: 20px 50px 20px 20px;
    color: white; /* Text color */
  }
  
  .contact-card-call {
    background: 
      linear-gradient(rgba(13, 17, 62, 0.8), rgba(13, 17, 62, 0.8)),
      url('../images/card-back.png') center/cover no-repeat;
      border-radius: 8px;
      height: 184px;
    
  }
  
  .contact-card-email {
    background: 
      linear-gradient(rgba(190, 40, 96, 0.9), rgba(190, 40, 96, 0.9)),
      url('../images/card-back.png') center/cover no-repeat;
      border-radius: 8px;
      margin-top:10px ;
      height: 184px;
  }
  
  .contact-card-visit {
    background: 
    linear-gradient(rgba(13, 17, 62, 1.0), rgba(13, 17, 62, 1)),
      url('../images/card-back.png') center/cover no-repeat;
      border-radius: 8px;
      margin-top:10px ;
      height: 184px;
  }
  .contact-card-content{
    padding: 25px 35px 25px 25px;  /* top right bottom left */
  }
  .contact-card form {
    display: flex;
    flex-direction: column;
   
  }
  
  .contact-card h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .form-group {
    /* margin: 10px 0; */
    /* text-align: ; */
  }
  
  .form-group label {
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
    color: #0D113E;
    text-align: left;
    margin-left: 20px;
    margin-top: 0px;
  }
  
  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="tel"],
  .form-group textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #EFD9EC;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.1); 
    color: #000;
    font-size: 16px;
    
  }
  
  .form-group select {
    color: black; /* Change the text color for the select element */
    background-color: rgba(255, 255, 255, 0.7); /* Change the select background color */
  }
  
  button[type="submit"] {
    background-color: #BE2860;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    margin-top: 10px;
    width: 90%;
    margin-left: 20px;
    margin-top: 0px;
  }
  
  button[type="submit"]:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background-color: #A01E49;
  }
  @media screen and (max-width: 800px) {
    .contact-container {
        flex-direction: column;
        align-items: center;
    }
    .contact-container-1 {
      max-width: 100%; /* Adjust the maximum width as needed */
      width: 100%;
      margin: 0 10px; /* Adjust margin for spacing */
  }
    .contact-card {
      max-width: 100%; /* Adjust the maximum width as needed */
      width: 100%;
      margin: 0 10px 10px -0px; /* Adjust margin for spacing */
  }
  .contact-card form {
    width: 90%;
}
    .contact-card-content{
      padding: 15px;
    }
    .contact-card-call{
      padding: 10px 10px 10px 10px;
    }
}


.success-message {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  margin: 10px 0;
  text-align: center;
  border-radius: 10px;
}

.error-message {
  background-color: #f44336;
  color: white;
  padding: 10px;
  margin: 10px 0;
  text-align: center;
}

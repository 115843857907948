.body {
    background: url("../images/wallpaperflare\ 1.png") center/cover no-repeat;
}

.heroo {

    display: flex;
    align-items: center;
    padding: 100px 70px 200px 70px;
    color: #0D113E;
    justify-content: center;
    /* top right bottom left */
}

.hero-text {
    flex: 1;
    text-align: left;

}

.hero-text h1 {
    font-size: 36px;
    margin: 0;
    color: #0D113E;
    font-weight: 700;
    font-family: 'HumeGeo', sans-serif;
    line-height: normal;
    font-style: normal;
    
}

.hero-text p {
    font-size: 16px;
    text-align: justify;
    font-family: 'HumeGeo1', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    padding-right: 20px;
    
}

.hero-image {
    flex: 1;
    text-align: center;
    order: 1;
}

.hero-image img {
    max-width: 100%;
    height: auto;
}




.text-card p {
    color: #FFFFFF;
    line-height: 26px;
    font-weight: 400;
    font-size: 20px;
}

.text-card {
    display: flex;
    background: 
    linear-gradient(rgba(13, 17, 62, 0.9), rgba(13, 17, 62, 0.9)),
      url('../images/card-imageback.png') center/cover no-repeat;
    border-radius: 8px;
    overflow: hidden;
    margin: 50px 50px 50px 50px;
    padding: 100px 80px 80px 80px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .text-card p{
    color: #FFFFFF;
    line-height: 26px;
    font-weight: 400;
    font-size: 20px;
  }
.text-learn-more {
    background-color: #BE2860;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    align-items: center;
    text-decoration: none;
}

.text-learn-more:hover {
    border: 1px solid var(--var-purple);
    background-color: transparent;
    color: #fff;
}

.image-card-container {
    display: flex;
    justify-content: space-around;
    padding: 50px;
    background: #FFF6FC;
}

.image-card {
    flex: 0 0 calc(29% - 20px);
    margin: auto;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s ease-in-out;
}

.image-text-h3 {
    text-align: left;
    color: #0D113E;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    font-family: 'HumeGeo', sans-serif;

}

.image-text-p {
    text-align: left;
    color: #0D113E;
    font-size: 14px;
    font-family: 'HumeGeo', sans-serif;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    
}

.image-card:hover {
    transform: scale(1.05);
}

.image-card img {
    max-width: 100%;
    height: auto;

}

.image-card-background {
    background: linear-gradient(to bottom, rgba(10, 22, 157, 0.9), rgba(10, 22, 157, 0.9)), url('../images/card-imageback.png');
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 50px 50px 50px 50px;
    /* top right bottom left */
}

.image-card-backgroundpl {
    background: linear-gradient(to bottom, rgba(143, 25, 170, 0.9), rgba(143, 25, 170, 0.9)), url('../images/card-imageback.png');
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 50px 50px 50px 50px;
    /* top right bottom left */
}

.image-card-backgroundpll {
    background: linear-gradient(to bottom, rgba(13, 17, 62, 0.9), rgba(13, 17, 62, 0.9)), url('../images/card-imageback.png');
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 50px 50px 50px 50px;
    /* top right bottom left */
}

.image-card p {
    margin-top: 10px;
    font-size: 16px;
    color: #333;

}

.image-text-container {
    margin: auto;
    padding: 5px 20px 5px 20px;
}

.image-container {
    display: flex;

}

.image-with-overlay {
    position: relative;
}

.image-with-overlay img {
    max-width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(190, 40, 96, 0.9), rgba(190, 40, 96, 0.9)), url('../images/card-imageback.png');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
    color: #fff;

}

.overlay h2 {
    font-size: 30px;
    margin-bottom: 10px;
    text-align: left;
    padding: 20px 60px 20px 60px;
    color: #FFF;
    font-weight: 600;
    font-family: 'HumeGeo', sans-serif;
    line-height: 140%;
    font-style: normal;
    

}

.overlay p {
    font-size: 16px;
    margin-bottom: 10px;
    padding: 20px 60px 20px 60px;

}

.overlay-learn-more {
    width: 100px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 60px;
}

.overlay button:hover {
    background-color: #A01E49;
}


.image-container img:last-child {
    max-width: 50%;
    height: auto;
}


@media (max-width: 800px) {
    .heroo {
        flex-direction: column;
        padding: 50px 0 100px 0;
    }

    .hero-text {
        text-align: center;
       width: 90%;
    }

    .hero-text p {
        font-size: 10px;
        text-align: center;
        padding: 20px;
        width: 90%;
    }
    

    .image-card-container {
        display: block;
        padding: 20px;
        background: #FFF6FC;
    }

    .image-card {
        margin-bottom: 30px;
        width: 100%;

    }

    .image-container {
        flex-direction: column;
        align-items: center;
    }

    .image-container img {
        max-width: 100%;
    }

    .overlay {
        align-items: center;
        padding: 30px 0px 30px 0px;
    }

    .overlay h2 {
        font-size: 20px;
        text-align: center;
        padding: 10px ;

    }

    .overlay p {
        font-size: 14px;
        text-align: center;
        margin-bottom: 10px;
        padding: 0px 0px 5px 0px;
        line-height: 19.6px;
        justify-content: center;

    }

    .text-card {
        margin: 50px 0;
        /* Remove horizontal margin on smaller screens */
        padding: 50px 10px;
        /* Adjust padding for smaller screens */
        border-radius: 0px;
    }

    

    .ima {
        height: auto;
        display: none;
    }

    .imag {
        max-width: 100%;
        max-height: 100%;

    }

}

@media (max-width: 900px) {
    .image-container {
        flex-direction: column;
        align-items: center;
    }

}

@media (min-width: 801px) {
    .imag {
        display: none;
    }
}
/* Your existing styles */

/* Media query for screens between 501px and 800px (tablet screen) */
@media (min-width: 801px) and (max-width: 1112px) {
    .image-card-container {
        flex-wrap: wrap;
        justify-content: space-around; /* Center the cards on the row */
        padding: 20px; /* Adjust padding as needed */
    }

    .image-card {
        flex: 0 0 calc(45% - 20px); /* Two cards per row with some spacing, adjust as needed */
        margin: 10px; /* Adjust spacing between cards as needed */
    }

    .image-text-h3,
    .image-text-p {
        width: 100%;
    }
}

/* Your remaining styles */

:root {
  --var-dark-blue: #222572;
  --var-fade-blue: #7476A6;

  --var-brwnish-prple:#AF3C63;
  --var-pink-prple:#EB81A5;

  --var-purple: #b72760;

  --var-blackish: #1d1c1c;

  --var-box-brcle: #DAD9E7;

  --var-white: #ffff;
}
@font-face {
  font-family: 'HumeGeo'; 
  src: url('../../HurmeGeometricSans2/HurmeGeometricSans2-Black.woff2') format('woff2'), /* Adjust the file path and format */
       url('../../HurmeGeometricSans2/HurmeGeometricSans2-BlackObl.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HumeGeo1'; 
  src: url('../../HurmeGeometricSans2/HurmeGeometricSans2-Regular.woff2') format('woff2'), /* Adjust the file path and format */
       url('../../HurmeGeometricSans2/HurmeGeometricSans2-Regular.woff') format('woff');
 
}

/* Rest of your CSS code */

.intro-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-image: url(../images/wallpaperflare\ 1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.money-bg {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-image: url(../images/hispanic-hand-holding-australian-dollars-banknotes-isolated-pink-background\ 1\ 2.svg);
  background-repeat: no-repeat;
  background-size: 30% 100%;
  background-position: 100% 0%;
}




.intro-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  padding: 10px 40px;
}

.intro-nav-social {
  display: flex;
  background-color: #fee9f0;
  border-radius: 10px;
}

.intro-nav-social a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  
  
}

.intro-nav-social a img {
  max-width: 15px;
  height: fit-content;
  cursor: pointer;
}

.intro-main{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 40px;
  min-width: 400px;
}
.intro-main :where(h1){
  font-weight: 700;
  font-size: 52px;
  margin-bottom: 0%;
  font-family: 'HumeGeo', sans-serif;
}
.intro-main :where(p){
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  margin-top: 0%;
}
.intro-main :where(h1,p) {
  color: #010138;
}

.intro-main p {
  line-height: 1.3;
  width: 400px;
  font-weight: 400;
  font-family: 'HumeGeo1', sans-serif;
  
  
}

.intro-btn {
  text-decoration: none;
  width: fit-content;
  font-size: 1rem;
  padding: 15px 20px;
  border-radius: 5px;
  color: var(--var-white);
  background-color: var(--var-purple);
  border: 2px solid transparent;
  box-shadow: 0px 10px 5px -8px var(--var-pink-prple);
  font-family: 'HumeGeo1', sans-serif;
}

.intro-btn:hover {
  border: 2px solid var(--var-purple);
  background-color: transparent;
  color: var(--var-purple);
}

.champ-offers {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  padding: 20px 40px;
}
.champ-offers p{
  text-align: center;
}
.champ-offers a {
  display: flex;
  align-items: center;
  width: 300px;
  min-width: 16%;
  max-width: 16%;
  gap: 5px;
  text-decoration: none;
  color: var(--var-dark-blue);
  font-weight: 600;
  padding: 5px;
  border-radius: 4px;
  text-align: center;
  font-family: 'HumeGeo1', sans-serif;
}

.champ-offers a:nth-of-type(1) {
  background-color: #ffd4db;
  text-align: center;
}
.champ-offers a:nth-of-type(2) {
  background-color: #cdcdff;
  text-align: center;
}
.champ-offers a:nth-of-type(3) {
  background-color: #d2ffd2;
}
.champ-offers a:nth-of-type(4) {
  background-color: rgb(255, 213, 198);
  
}

.champ-offers a img {
  width: 25px;
  height: fit-content;
  max-width: 25px;
  max-height: fit-content;
}




@media (max-width: 1000px) {
  .money-bg {
      background-size: 50% 100%;
  }
}
@media (max-width: 700px) {
  .intro-nav, .intro-main {
      padding-left: 20px;
      padding-right: 20px;
      
  }
  .champ-offers a {
    display: flex;
    align-items: center;
    width: 300px;
    min-width: 100%;
    max-width: 100%;
    gap: 5px;
    text-decoration: none;
    color: var(--var-dark-blue);
    font-weight: 600;
    padding: 5px;
    border-radius: 4px;
    text-align: center;
  }
  .intro-main :where(h1){
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 0%;
    text-align: center;
  }
  .intro-main :where(p){
    font-weight: 400;
    font-size: 16px;
    margin: 0 auto;
    text-align: center;

  }
  .intro-main{
    text-align: center;
  }
  .intro-btn{
    margin:auto
  }

  .money-bg {background-size: 0;}
}



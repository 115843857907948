
  
.body {
    background: url("../images/wallpaperflare\ 1.png") center/cover no-repeat;
}

.herooo {

    display: flex;
    align-items: center;
    padding: 100px 70px 200px 70px;
    color: #0D113E;
    justify-content: center;
    /* top right bottom left */
}

.herooo-text {
    flex: 1;
    text-align: left;

}

.herooo-text h1 {
    font-size: 36px;
    margin: 0;
    color: #0D113E;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: 'HumeGeo', sans-serif;

    

}

.herooo-text p {
   
    text-align: justify;
    color: rgba(13, 17, 62, 0.60);
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-family: 'HumeGeo1', sans-serif;
    line-height: 160%;
    
    
}

.herooo-image {
    flex: 1;
    text-align: center;
    order: 1;
}

.herooo-image img {
    max-width: 100%;
    height: auto;
}


.text-card {
    display: flex;
    background:
        linear-gradient(rgba(13, 17, 62, 0.9), rgba(13, 17, 62, 0.9)),
        url('../images/card-imageback.png') center/cover no-repeat;
    border-radius: 8px;
    overflow: hidden;
    margin: 180px 70px 150px 70px;
    padding: 100px 80px 80px 80px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.text-card p {
    color: #FFFFFF; 
    line-height: 26px;
    font-weight: 400;
    font-size: 20px;
}

.text-learn-more {
    background-color: #BE2860;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    align-items: center;
}

.text-learn-more:hover {
    border: 1px solid var(--var-purple);
    background-color: transparent;
    color: #fff;
}

.image-card-container-payment {
    display: flex;
    justify-content: space-around;
   /* padding: 50px; */
    background: #FBF6FF;
}
.header-title{
    background: #FBF6FF;
    top: 0;
    justify-content: center;
    text-align: center;
    padding: 20px;
    color: #0D113E;
    font-style: normal;
    font-weight: 700;
    font-family: 'HumeGeo', sans-serif;
}
.image-card-payment {
    flex: 0 0 calc(25% - 20px);
    margin: auto;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.2s ease-in-out;
}

.image-text-h3 {
    text-align: left;
    color: #0D113E;
    font-family: 'HumeGeo', sans-serif;
    font-weight: 600;
    line-height: normal;
    font-size: 24px;
    
   
}

.image-text-p {
    text-align: left;
    color: #0D113E;
    font-family: 'HumeGeo1', sans-serif;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 160%;
    
    
}

.image-card:hover {
    transform: scale(1.05);
}

.image-card img {
    max-width: 100%;
    height: auto;

}



.image-card p {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
   
}
.image-text-h3,
.image-text-p {
    width: 100%;
}
.image-text-container-payment{
    margin: auto;
    padding: 5px 20px 5px 20px;
}
.image-container-payment {
    display: flex;
   
}
.image-container-payment img{
    max-width: 50%;
}

.image-with-overlay-payment {
    position: relative;
}

.image-with-overlay-payment img {
    max-width: 100%;
    height: auto;
}
.overlay-payment {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(34, 38, 107, 1) , rgba(34, 38, 107, 1)), url('../images/card-imageback.png');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
    color: #fff; 
   
}

.overlay-payment h2 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left;
    padding: 20px 60px 20px 60px;
    font-family: 'HumeGeo', sans-serif;
    font-weight: 600;
    line-height: 140%;
    font-style: normal;
    
    
    
}

.overlay-payment p {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 20px 60px 20px 60px;
    color: #FFF;
    font-family: 'HumeGeo1', sans-serif;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    

}

.overlay-learn-more-payment{
    width: 100px; 
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 60px;
}

.overlay button:hover {
    background-color: #A01E49;
}


.image-container img:last-child {
    max-width: 50%; 
    height: auto;
}
.header-title{
    color: #0D113E;
}

@media (max-width: 800px) {
    .herooo {
        flex-direction: column; /* Change to column layout */
        padding: 10px; /* Adjust padding as needed */
        margin-top: 30px;
    }
    .herooo-text {
        flex: 1;
        text-align: center;
        font-size: 20px;
    
    }
    
    .herooo-text h1 {
        font-size: 20px;
        margin: 0;
    }
    
    .herooo-text p {
        font-size: 15px;
        text-align: center;
    }
    .image-card-container-payment {
        display: block;
        background: #FBF6FF;
    }
    .image-text-h3,
    .image-text-p {
        width: 100%;
    }
    .image-text-container-payment{
        margin-bottom: 0px;
    }
    .image-container-payment {
        display: block;
       
    }
    .image-container-payment img{
        max-width: 100%;
        margin-bottom: 0px;
    }
    
    .image-with-overlay-payment {
        position: block;
    }
    
    .image-with-overlay-payment img {
        max-width: 100%;
        height: auto;
    }
    .overlay-payment {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(34, 38, 107, 1) , rgba(34, 38, 107, 1)), url('../images/card-imageback.png');
        display: inline-block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff; 
        margin: auto;
        padding-bottom: 40px;
       
    }
    
    .overlay-payment h2 {
        font-size: 20px;
        margin-bottom: 5px;
        text-align: center;
        padding: 5px 10px 5px 10px;
        font-weight: 600;
        
    }
    
    .overlay-payment p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 30px;
        padding: 5px 10px 5px 10px;
    
    }
    .overlay-learn-more-payment{
        width: 80px; 
        color: #fff;
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 16px;
        margin-left: 15px;
        margin-top: 40px;
        margin-bottom: 50px;
    }
   
}
/* Your existing styles */
@media (min-width: 801px) and (max-width: 1112px) {
    .image-card-container-payment {
        flex-wrap: wrap;
        justify-content: space-around; /* Center the cards on the row */
    }

    .image-card-payment {
        flex: 0 0 calc(45% - 20px); /* Two cards per row with some spacing, adjust as needed */
        margin: 10px; /* Adjust spacing between cards as needed */
    }

    .image-text-h3,
    .image-text-p {
        width: 100%;
    }
}
:root {
  --var-dark-blue: #222572;
  --var-fade-blue: #7476A6;

  --var-brwnish-prple:#AF3C63;
  --var-pink-prple:#EB81A5;

  --var-purple: #b72760;

  --var-blackish: #1d1c1c;

  --var-box-brcle: #DAD9E7;

  --var-white: #ffff;
}

.landingpage-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  padding: 20px 50px;
  position: sticky;
  top: 0px;
  background-color: transparent;
}

.landingpage-nav-logo {
  width: 100%;
  max-width: 80px;
  height: fit-content;
  max-height: fit-content;
}

.landingpage-submenu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 200px;
}

.landingpage-nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}.landingpage-nav-links a {
  text-decoration: none;
  font-size: 14px;
  color: #222572;
  transition: .5s;
  position: relative;
  font-weight: 600;
}
.landingpage-nav-links a::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -3px;
  left: 0;
  background-color: var(--var-brwnish-prple);
  transition: .5s;
}

.landingpage-nav-links a:hover {
  color: var(--var-purple);
}.landingpage-nav-links a:hover::after {
  width: 100%;
}
.nav-btn {
  text-decoration: none;
  font-size: 1rem;
  padding: 10px 10px;
  border-radius: 4px;
  color: var(--var-white);
  background-color: var(--var-purple);
  border: 1px solid transparent;
  box-shadow: 0px 10px 5px -8px var(--var-pink-prple);
  
}

.nav-btn:hover {
  border: 1px solid var(--var-purple);
  background-color: transparent;
  color: var(--var-purple);
}

.ham-btn {
  display: none;
  width: 30px;
  height: fit-content;
  max-width: 30px;
  max-height: fit-content;
}

.mobile-nav {
  display: none;
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  padding: 20px;
  background-color: rgba(128, 128, 128, 0.516);
  z-index: 20;
}

.join-waitlist-modal {
  background-color: var(--var-white);
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  min-width: 320px;
}
.jwm-subheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.jwm-form div{
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
}

.jwm-form div label {
  font-weight: 700;
  opacity: .8;
  color: var(--var-blackish);
}

.jwm-form div input,.jwm-form div select {
  height: 40px;
  width: 100%;
  padding-left: 10px;
  border-radius: 5px;
  border: 2px solid #bebebe;
}

.jwm-form button {
  text-decoration: none;
  font-size: 1rem;
  padding: 15px 20px;
  border-radius: 5px;
  color: var(--var-white);
  background-color: var(--var-purple);
  border: 2px solid transparent;
  box-shadow: 0px 10px 5px -8px var(--var-pink-prple);
  margin-top: 20px;
}

.active{
  color: #b72760;
}

@media (max-width:  1024px) {
 

  .landingpage-nav {
      padding: 10px 20px;
  }

  .landingpage-submenu {
      display: none;
  }

  .ham-btn {
      display: block;
  }

  .mobile-nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: var(--var-white);
      z-index: 10;
  }

  .mobile-nav-subheading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
  }

  .mobile-nav .close-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      width: 30px;
      height: 30px;
      border: 2px solid var(--var-purple);
      padding-left: 6px;
      border-radius: 50%;
  }
  .close-btn span{
      display: block;
      width: 20px;
      height: 2px;
      background-color: var(--var-purple);
  }

  .close-btn span:nth-of-type(1) {
      transform: rotate(45deg) translateY(4.7px);
  }
  .close-btn span:nth-of-type(2) {
      transform: rotate(-45deg) translateY(-4.7px);
  }

  .mn-landingpage-submenu {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 20px;
  }

  .mn-landingpage-submenu .landingpage-nav-links {
      flex-direction: column;
      align-items: start;
      gap: 20px;
      
  }

  .landingpage-nav-links .mn-nav-linkk {
      font-size: 1rem;
  }
  .landingpage-nav-links a {
    text-decoration: none;
    font-size: 14px;
    color: #222572;
    transition: .5s;
    position: relative;
    font-weight: 700;
  }

  .copyright-text {
      position: absolute;
      text-align: center;
      top: 90%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 93%;
      padding-top: 20px;
      border-top: 2px solid grey;
      color: grey;
      font-size: .8rem;
  }
  .landingpage-nav-logo {
    width: 70px;
    max-width: 70px;
}
.nav-btn{
  width: 30%;
  text-align: center;
}
}

@media (max-width: 500px) {
  .landingpage-nav-logo {
      width: 30px;
      max-width: 30px;
  }
}
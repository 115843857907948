.body {
    background: url("../images/wallpaperflare\ 1.png") center/cover no-repeat;
}

.loan-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    /* Update padding as needed */
    color: #0D113E;
    text-align: center;
}

.loan-hero-text {
    max-width: 800px;
    /* Adjust the max width as needed */
    position: absolute;

    /* Add a background color to make text readable */
    border-radius: 15px;
    /* Add a border-radius for rounded corners */
    padding: 20px;
    /* Add padding for spacing */
    top: 60%;
    /* Position the text 50% from the top */
    left: 50%;
    /* Position the text 50% from the left */
    transform: translate(-50%, -50%);
    /* Center the text both horizontally and vertically */

}

.loan-hero-text h1 {
    font-size: 30px;
    margin: 0;
    color: #0D113E;
    font-weight: 700;
    font-family: 'HumeGeo', sans-serif;
    font-style: normal;
    line-height: normal;
    
    
}

.loan-hero-text p {
    font-size: 16px;
    text-align: center;
    padding-bottom: 30px;
    color: rgba(13, 17, 62, 0.60);
    font-weight: 400;
    font-style: normal;
    font-family: 'HumeGeo1', sans-serif;
    line-height: 160%;
    
    
    
}

.loan-hero-text a {
    border: 1px solid #BE2860;
    border-radius: 4px;
    color: #BE2860;
    text-decoration: none;
    padding: 13px 24px 13px 24px;
    font-family: 'HumeGeo', sans-serif;
}

.loan-hero-text a:hover {
    background-color: #BE2860;
    color: #FFFFFF;
    transition: background-color 0.5s ease 0.5s, color 0.5s ease 0.5s;

}


.loan-hero-image {
    width: 100%;
    text-align: center;
    padding: 0px;
   
}

.loan-hero-image img {
    width: 99.8%;
    max-width: 100%;
    height: auto;
}

.backboy{
    background: #F8F8FE;
   
    
}
/*  */

.image-card-container-loan {
    display: flex;
    flex-direction: column;
    /* max-width: auto; */
    padding: 30px;
    background: #F8F8FE;
   
}

.image-card-loan {
    display: flex;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;


}

.image-card-loan img {
    flex: 0 0 25%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}


.image-text-container-loan {
    flex: 1;
    padding: 30px;
    margin: auto;
    background: #fff;
    border-radius: 10px;
}

.image-text-loan-h3 {
    font-size: 24px;
    color: #0D113E;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    font-family: 'HumeGeo1', sans-serif;
}

.image-text-loan-p {
    font-size: 14px;
    color: #0D113ECC;
    font-style: normal;
    line-height: 160%;
    font-weight: 400;
    font-family: 'HumeGeo1', sans-serif;
    
}

.image-text-loan-p-push {
    font-size: 14px;
    color: #0D113ECC;
    padding-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    font-family: 'HumeGeo1', sans-serif;
    

}

.family-btn {
    border-color: transparent;
    border-radius: 4px;
    color: #fff;
    background-color: #BE2860;
    text-decoration: none;
    padding: 13px 24px 13px 24px;
    width: 130px;
    font-size: 14px;
    font-weight: 600;


}

.family-btn:hover {
    background-color: transparent;
    color: #BE2860;
    border: 1px solid #BE2860;

}

.food-btn {
    margin-top: 5px;
    border: 2px solid #BE2860;
    border-radius: 4px;
    color: #BE2860;
    text-decoration: none;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    font-weight: 600;
}

.food-btn:hover {
    background-color: #BE2860;
    color: #FFFFFF;

}

.image-text-loan-p {
    display: flex;
    flex-wrap: wrap;
}

.image-text-loan-pp {
    display: flex;
    flex-wrap: wrap;
}

.image-text-loan-pp p {
    width: 50%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    font-weight: 600;
    font-size: 13px;
    font-style: normal;
    font-family: 'HumeGeo1', sans-serif;
    
}

.image-text-loan-pp p::before {
    content: "•";
    color: #BE2860;
    margin-right: 10px;
}

.image-text-loan-p p {
    width: 50%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}


.image-text-loan-p p::before {
    content: "•";
    color: #BE2860;
    margin-right: 10px;
}

.image-text-loan-listt {
    font-size: 16px;
    color: #0D113E;
    display: none;
}

.image-text-loan-listt ul {
    list-style: none;
    padding: 10px;
}

.image-text-loan-listt li::before {
    content: '•';
    /* Use the bullet character as content */
    color: #BE2860;
    margin-right: 10px;
    /* Adjust the spacing between the bullet and text */
}

.image-text-loan-list {
    font-size: 16px;
    color: #0D113E;
}

.image-text-loan-list ul {
    list-style: none;
    padding: 10px;
    color: #0D113E;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-family: 'HumeGeo1', sans-serif;
    line-height: 160%;
    
    
}


.image-text-loan-list li::before {
    content: '•';
    /* Use the bullet character as content */
    color: #BE2860;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    
    /* Adjust the spacing between the bullet and text */
}

/*  */



.text-card p {
    color: #FFFFFF;
    line-height: 26px;
    font-weight: 400;
    font-size: 20px;
}

.text-learn-more {
    background-color: #BE2860;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    align-items: center;
}

.text-learn-more:hover {
    border: 1px solid var(--var-purple);
    background-color: transparent;
    color: #fff;
}

.image-card-container-loan {
    display: flex;
    justify-content: space-around;
    /* padding: 50px; */
    background: #FBF6FF;
}

.header-title {
    background: #FBF6FF;
    top: 0;
    justify-content: center;
    text-align: center;
    padding: 20px;
}




.image-card p {
    margin-top: 10px;
    font-size: 16px;
    color: #333;

}

.image-text-container {
    margin: auto;
    padding: 5px 20px 5px 20px;
}

.image-container-loan {
    display: flex;

}

.image-container-loan img {
    max-width: 50%;
}





.image-container img:last-child {
    max-width: 50%;
    height: auto;
}

/* Calculator */

.card-form-loan {
    flex: 1;
    padding: 100px;
    margin: 20px;
    background: #FAF2F9;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.card-form-loan form {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.card-form-loan .form-group {
    margin: 10px 0;
}

.card-form-loan label {
    font-weight: bold;
}

.card-form-loan input,
.card-form select,
.form-select {
    padding: 10px;
    border: 1px solid #EFD9EC;
    border-radius: 7px;
    margin: 5px 0;
    color: #0D113E;
    width: 445px;
}

.form-group2 {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px 0;
    color: #0D113E;
}

.card-image-loan {
    flex: 1;
    background: linear-gradient(rgba(13, 17, 62, 1), rgba(13, 17, 62, 1)), url('../images/card-back.png');
    background-size: cover;
    background-position: center;
    color: #fff;
    padding: 50px;
    border-radius: 8px;
    width: 50%;
}

.overlay-loan h2 {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
    font-style: normal;
    font-family: 'HumeGeo', sans-serif;
    color: #FFF;
}
.overlay-loan h3 {
    margin: 0;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 140%;
    font-style: normal;
    font-family: 'HumeGeo', sans-serif; 
}

.est-text {
    text-align: center;
    color: #ffffffff;
}

.overlay-loan ul {
    list-style: none;
    padding: 0;
}

.overlay-loan li {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 10px; */
}

.overlay-loan li span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
}

.card-form-loan input[type="tel"] {
    color: black;
}

.card-form-loan button[type="button"] {
    background-color: #BE2860;
    /* Background color */
    color: #FFFFFF;
    /* Text color */
    border: none;
    padding: 15px;
    /* Adjust padding as needed */
    border-radius: 5px;
    /* Rounded corners */
    width: 92.5%;
    /* Take the full width of the container */
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Add a transition effect for hover */
    /* align-self: center; */
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
}

.card-form-loan button[type="button"]:hover {
    background-color: transparent;
    /* Change background color on hover */
    border: 1px solid #BE2860;
    color: #BE2860;
}

.form-select {
    background-color: transparent;
}

/* modal */
/* Styles for the Modal Content */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 990;
    /* Ensure it's above other content */
}

.join-waitlist-modal-loan {
    background: #fff;
    width: 70%;
    max-width: 300px;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 50%;
    /* Adjust this value to change the modal's initial position */
    transform: translateY(-50%);
    /* Center the modal vertically */
    z-index: 1000;
    /* Ensure it's above the backdrop */
}

.jwm-subheading-loan h1 {
    text-align: center;
    /* Center-align the heading */
    color: #333;
    /* Heading text color */
    font-family: 'HumeGeo', sans-serif;
    
}

.jwm-form-loan {
    display: inline-block;
    flex-direction: column;
    gap: 10px;
}

.jwm-form-loan label {

    color: #555;
    font-family: 'HumeGeo', sans-serif;
    /* Label text color */
}

.jwm-form-loan input,
.jwm-form-loan select {
    width: 100%;
    /* Expand input/select to full width */
    padding: 10px;
    border: 1px solid #ccc;
    /* Input border */
    border-radius: 5px;
}


.modal-close {
    position: absolute;
    top: 30px;
    right: 50px;
}

.modal-close img {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Add this CSS to your stylesheet */
.form-group select {
    width: 93;
    padding: 10px;
    border: 1px solid #EFD9EC;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #000;
    font-size: 16px;
    appearance: none;
    /* Remove default arrow icon */
    background-image: linear-gradient(45deg, transparent 50%, #BE2860 50%),
        linear-gradient(135deg, #BE2860 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
}

/* Add additional styling for hover/focus if needed */
.form-group select:hover,
.form-group select:focus {
    border-color: #BE2860;
    /* Change border color on hover/focus */
}


/* You can add more styles as needed */

/* modal end */


@media (max-width: 1024px) {
    .image-text-loan-pp {
        display: none;
    }
    .image-card-container-loan {
        flex-direction: column;
        max-height: 100%;
    }

    .image-card-loan {
        display: block;
        /* Change the display to block for small screens */
        padding-bottom: 40px;
    }

    .image-card-loan img {
        max-width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0px;
        width: '100%'
    }

    .family-btn {
        border-radius: 4px;
        color: #fff;
        background-color: #BE2860;
        text-decoration: none;
        padding: 10px 10px 10px 10px;
        width: 130px;
        font-size: 14px;
        font-weight: 600;
    }

    .family-btn:hover {
        background-color: transparent;
        color: #BE2860;
        border: 1px solid #BE2860;
    }

    .food-btn {
        margin-top: 5px;
        border: 1px solid #BE2860;
        border-radius: 4px;
        color: #BE2860;
        text-decoration: none;
        padding: 10px 20px 10px 20px;
        cursor: pointer;

    }

    .image-text-loan-h3 {
        font-size: 18px;
        color: #0D113E;
        text-align: start;
        padding: 0px;
    }

    .image-text-loan-p {
        text-align: start;
        font-size: 14px;
        font-weight: 400;
        /* Center-align the text for small screens */
    }

    .image-text-loan-p p {
        width: 100%;
        gap: 0;
        /* text-align: start; */
        font-size: 10px;
        align-items: baseline;
    }

    .image-text-loan-p p span {
        /* display: flex; */
        align-items: start;
        text-align: start;
        font-size: 10px;
    }

    /*         
        .image-text-loan-p p {
            width: 50%; 
            display: flex;
            
            gap: 10px; 
        } */
    .image-text-loan-p-push {
        font-size: 10px;
        color: #000;
        padding: 0px;
        text-align: start;

    }
    .loan-hero {
        /* flex-direction: column-reverse;  */
        align-items: center;
        display: none;
    }

    .card-form-loan {
        flex-direction: column;
        width: fit-content;
        margin: auto;
        border-radius: 0px;
    }

    .card-form-loan form {
        width: 100%;
    }

    .card-image-loan {
        width: 100%;
        padding: 20px;
        margin-top: 20px;
    }

    .card-image-loan {
        flex: 1;
        background: linear-gradient(rgba(13, 17, 62, 1), rgba(13, 17, 62, 1)), url('../images/card-back.png');
        background-size: cover;
        background-position: center;
        color: #fff;
        padding: 20px;
        border-radius: 8px;
        width: auto;
    }

    .card-form-loan button[type="button"] {
        background-color: #BE2860;
        /* Background color */
        color: #FFFFFF;
        /* Text color */
        border: none;
        padding: 10px;
        /* Adjust padding as needed */
        border-radius: 5px;
        /* Rounded corners */
        width: auto;
        /* Take the full width of the container */
        cursor: pointer;
        transition: background-color 0.3s ease;
        /* Add a transition effect for hover */
        /* align-self: center; */
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
    }

    .card-form-loan button[type="button"]:hover {
        background-color: transparent;
        /* Change background color on hover */
        border: 1px solid #BE2860;
        color: #BE2860;
    }

    .card-form-loan input,
    .card-form select,
    .form-select {
        padding: 10px;
        border: 1px solid #EFD9EC;
        border-radius: 7px;
        margin: 5px 0;
        color: #0D113E;
        width: auto;
    }

  
    .loan-hero-text-small {
        text-align: center;
        padding: 15px;
        margin-top: 10%;
    }

    .loan-hero-text-small a {
        border: 1px solid #BE2860;
        border-radius: 4px;
        color: #BE2860;
        text-decoration: none;
        padding: 13px 24px 13px 24px;
    }

    .loan-hero-text-small a:hover {
        background-color: #BE2860;
        color: #FFFFFF;
        transition: background-color 0.5s ease 0.5s, color 0.5s ease 0.5s;

    }

    .join-waitlist-modal-loan {
        background: #fff;
        width: 100%;
        max-width: 300px;
        padding: 40px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 50%;
     
        transform: translateY(-50%);
     
        z-index: 1000;
     
    }

    .card-form-loan {
        /* flex: 1; */
        padding: 5px;
        background-color: #f4f4f4;
        width: 95%;
        max-width: 95%;


    }

    .form-group select {
        width: 95%;
        padding: 10px;
        border: 1px solid #EFD9EC;
        border-radius: 7px;
        background-color: rgba(255, 255, 255, 0.1);
        color: #000;
        font-size: 16px;
        appearance: none;
        background-image: linear-gradient(45deg, transparent 50%, #BE2860 50%),
            linear-gradient(135deg, #BE2860 50%, transparent 50%);
        background-position: calc(100% - 20px) calc(1em + 2px),
            calc(100% - 15px) calc(1em + 2px);
        background-size: 5px 5px, 5px 5px;
        background-repeat: no-repeat;
    }
    .form-group select:hover,
    .form-group select:focus {
        border-color: #BE2860;
    }
    .image-text-loan-listt {
        font-size: 13px;
        color: #0D113E;
        display: block;
    }
    .image-text-loan-listt ul {
        list-style: none;
        padding: 10px;
    }

    .image-text-loan-listt li::before {
        content: '•';
        color: #BE2860;
        margin-right: 10px;
       
    }
}

@media (min-width: 1025px) {
    .loan-hero-small {
        display: none;
    }
}

.form-group {
    margin-bottom: 20px;
}

.form-input,
.form-select {
    width: 93%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px 0;
    color: #0D113E;
    border-color: transparent;
}

.form-select {
    background-color: transparent;
}

@media screen and (min-width: 768px) {
    .form-group {
        margin-bottom: 30px;
    }

    .form-input,
    .form-select {
        font-size: 18px;
    }
}


@media screen and (min-width: 992px) {
    .form-group {
        margin-bottom: 40px;
    }

    .form-input,
    .form-select {
        font-size: 20px;
    }
}


.body{
    background: url("../images/wallpaperflare\ 1.png") center/cover no-repeat;
}
@font-face {
  font-family: 'HumeGeo'; 
  src: url('../../HurmeGeometricSans2/HurmeGeometricSans2-Black.woff2') format('woff2'), /* Adjust the file path and format */
       url('../../HurmeGeometricSans2/HurmeGeometricSans2-BlackObl.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HumeGeo1'; 
  src: url('../../HurmeGeometricSans2/HurmeGeometricSans2-Regular.woff2') format('woff2'), /* Adjust the file path and format */
       url('../../HurmeGeometricSans2/HurmeGeometricSans2-Regular.woff') format('woff');
 
}

.hero {
   
    display: flex;
    align-items: center;
    padding: 100px 70px 0 70px;
    color: #0D113E;
    justify-content: center;
  }
  
  .hero-text {
    flex: 1;
    text-align: left;
    
  }
  
  .hero-text h1 {
    font-size: 50px;
    margin: 0;
    color: #0D113E;
    font-family: 'HumeGeo', sans-serif;
    font-style: normal;
    line-height: normal;
  }
  
  .hero-text p {
    font-size: 16px;
    text-align: justify;
    color: #0D113E;
    padding: 0%;
    font-style: normal;
    line-height: 160%;
    font-family: 'HumeGeo1', sans-serif;
    width: 609px;
  }
  
  .hero-image {
    flex: 1;
    text-align: center;
    order: 1;
  }
  
  .hero-image img {
    max-width: 100%;
    height: auto;
  }
  
/* Styles for the new divs */
.new-divs {
    display: flex;
    justify-content: space-between;
    padding: 50px 70px 0 70px;
  }
  
  .mission,
  .vision {
    flex: 0 0 calc(50% - 20px);
    padding: 20px;
  }
  
  /* Additional styling for h2 and p within the new divs */
  .mission h2,
  .vision h2 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
    line-height: normal;
    font-family: 'HumeGeo', sans-serif;
    color: #0D113E;
    

  }
  
  .mission p,
  .vision p {
    font-size: 14px;
    color: #0D113E;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    font-family: 'HumeGeo1', sans-serif;
    
  }
  
  .section-with-background {
    background: 
    linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
      url('../images/moneygrow.jpg') center/cover fixed ;
    position: relative;
    text-align: center;
    padding: 100px 0;
    color: #fff;
  }

  
  .text-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .columns {
    flex: 0 0 calc(33.33% - 20px);
    padding: 30px;
    box-sizing: border-box;
    text-align: left;
  }
  .columns.above h2{
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: #FFF;
    line-height: normal;
    font-family: 'HumeGeo', sans-serif;
    
  }
  .columns.above p{
    margin-left: 50px;
    color: #FFF;
    font-weight: 400;
    line-height: 160%;
    font-size: 16px;
    font-style: normal;
    font-family: 'HumeGeo1', sans-serif;
    
  }
/* card.css */

.card {
  display: flex;
  background: 
  linear-gradient(rgba(13, 17, 62, 0.9), rgba(13, 17, 62, 1)),
    url('../images/card-imageback.png') center/cover no-repeat;
  border-radius: 8px;
  overflow: hidden;
  margin: 50px 50px 50px 50px;
  padding: 100px 80px 80px 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.card p{
  color: #FFF;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  font-family: 'HumeGeo1', sans-serif;
  
  
}
.learn-more {
  background-color: #BE2860;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 13px 27px;
  cursor: pointer;
  font-size: 20px;
 align-items: center;
 text-decoration: none;
 gap: 10px;
 justify-content: center;
 display: inline-flex;
 
 
}

.learn-more:hover {
  border: 1px solid var(--var-purple);
  background-color: transparent;
  color: #fff;
}
@media (max-width: 768px) {
  .hero {
      flex-direction: column;
      align-items: center;
      padding: 20px 10px;
  }

  .hero-text {
      text-align: center;
      padding: auto;
  }

  .hero-text h1 {
      font-size: 24px;
      margin-bottom: 10px;
      text-align: center;
  }

  .hero-text p {
      font-size: 20px;
      text-align: center;
  }

  .hero-image {
      order: 2;
      margin-top: 20px;
  }

  .new-divs {
      flex-direction: column;
      padding: 20px;
  }

  .mission,
  .vision {
      flex: 1;
      margin-bottom: 10px;
  }
  .text-container {
    flex-direction: column;
}

.columns {
    flex: 0 0 100%; 
    padding: 20px;
}

.columns.above p {
    margin-left: 0; 
}
.card {
  margin:  auto 0; 
  padding: 30px 10px; /* Adjust padding for smaller screens */
  border-radius: 0px;
}

}








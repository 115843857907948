.body {
    background: url("../images/wallpaperflare\ 1.png") center/cover no-repeat;
}

.heroooo {

    display: flex;
    align-items: center;
    padding: 100px 70px 200px 70px;
    color: #0D113E;
    justify-content: center;
    /* top right bottom left */
}

.heroooo-text {
    flex: 1;
    text-align: left;

}

.heroooo-text h1 {
    font-size: 40px;
    margin: 0;
    color: #0D113E;
    font-family: 'HumeGeo', sans-serif;
    line-height: normal;
    

}

.heroooo-text p {
    font-size: 16px;
    text-align: justify;
    font-family: 'HumeGeo1', sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 160%;
    
}

.heroooo-image {
    flex: 1;
    text-align: center;
    order: 1;
}

.heroooo-image img {
    max-width: 100%;
    height: auto;
}
/*  */
.image-card-container-thrift {
    display: flex;
    flex-direction: column;
    max-width: auto;
    padding: 30px;
  }
  
  .image-card-thrift {
    display: flex;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    
    
  }
  
  .image-card-thrift img {
    flex: 0 0 15%;
    background:
    linear-gradient(rgba(13, 17, 62, 0.8), rgba(13, 17, 62, 0.8)),
    url('../images/graphbackground.png') center/cover no-repeat;
    border-top-left-radius:10px ;
    border-bottom-left-radius:10px ;
    
  }
  
  .image-text-container-thrift {
    flex: 1;
    padding: 40px;
    margin: auto;
    background: #fff;
    border-radius: 10px;
  }
  
  .image-text-thrift-h3 {
    font-size: 18px;
    color: #0D113E;
    font-family: 'HumeGeo', sans-serif;
    font-weight: 600;
    line-height: normal;
    color: rgba(13, 17, 62, 0.80);
    
    
    ;
  }
  
  .image-text-thrift-p {
    color: #0D113E;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 160%;
    font-family: 'HumeGeo1', sans-serif;
    
  }
  /*  */

  /* right */
.image-card-container-thrift-right {
    display: flex;
    flex-direction: column;
    max-width: auto;
    padding: 50px;
   
  }
  
  .image-card-thrift-right {
    display: flex;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
   
    
  }
  
  .image-card-thrift-right img {
    flex: 0 0 15%;
    background:
    linear-gradient(rgba(190, 40, 96, 0.9), rgba(190, 40, 96, 0.9)),
    url('../images/graphbackground.png') center/cover no-repeat;
    border-top-right-radius:10px ;
    border-bottom-right-radius:10px ;
  
  }
  
  .image-text-container-thrift-right {
    flex: 1;
    padding: 5px 20px 5px 20px;
    margin: auto;
    background: #fff;
    border-radius: 10px;
  }
  
  .image-text-thrift-h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #000;
    font-weight: 600;
  }
  
  .image-text-thrift-p {
    font-size: 18px;
    color: #000;
    font-weight: 400;
  }
  /* right */
.text-card {
    display: flex;
    background:
        linear-gradient(rgba(13, 17, 62, 0.9), rgba(13, 17, 62, 0.9)),
        url('../images/card-imageback.png') center/cover no-repeat;
    border-radius: 8px;
    overflow: hidden;
    margin: 180px 70px 150px 70px;
    padding: 100px 80px 80px 80px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.text-card p {
    color: #FFFFFF;
    line-height: 26px;
    font-weight: 400;
    font-size: 20px;
}

.text-learn-more {
    background-color: #BE2860;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    align-items: center;
}

.text-learn-more:hover {
    border: 1px solid var(--var-purple);
    background-color: transparent;
    color: #fff;
}

.image-card-container-thrift {
    display: flex;
    justify-content: space-around;
   padding: 50px;
    background: #FBF6FF;
}
.header-title{
    background: #FBF6FF;
    top: 0;
    justify-content: center;
    text-align: center;
    padding: 20px;
}

.image-card p {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
   
}
.image-text-container{
    margin: auto;
    padding: 5px 20px 5px 20px;
}
.image-container-thrift {
    display: flex;
   
}
.image-container-thrift img{
    max-width: 50%;
}

.image-with-overlay-thrift {
    position: relative;
}

.image-with-overlay-thrift img {
    max-width: 100%;
    height: auto;
}
.overlay-thrift {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(190, 40, 96, 0.9), rgba(190, 40, 96, 0.9)), url('../images/card-imageback.png');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
    color: #fff; 
   
}

.overlay-thrift h2 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: left;
    padding: 20px 60px 20px 60px;
    line-height: 140%;
    font-family: 'HumeGeo', sans-serif;
    
}

.overlay-thrift p {
    font-size: 16px;
    margin-bottom: 10px;
    padding: 20px 60px 20px 60px;
    font-family: 'HumeGeo1', sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 140%;
    


}

.overlay-learn-more{
    width: 190px; 
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 13px, 27px, 13px, 27px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 60px;
    text-align: center;
    height: 28px;
}

.overlay button:hover {
    background-color: #A01E49;
}


.image-container img:last-child {
    max-width: 50%; 
    height: auto;
}
/* Media query for small screens */
@media (max-width: 800px) {
    .heroooo {
        flex-direction: column; /* Change to column layout */
        align-items: center; /* Center text vertically */
        padding: 20px; /* Reduce padding as needed */
    }
    .heroooo-text h1 {
        font-size: 20px;
        margin: 0;
        text-align: center;
    }
    
    .heroooo-text p {
        font-size: 15px;
        text-align: center;
    }
    
    .heroooo-image {
        flex: 1;
        text-align: center;
        order: 1;
    }
    
.image-card-container-thrift {
    display: block;
    justify-content: space-around;
    background: #FBF6FF;
    max-width: 100%;
    width: auto;
    padding: 10px;
}
.image-card-thrift {
    display: block;
    width: 100%;
    max-width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 0px
   
  }
 

.image-card-thrift img {
   width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0px;
 
}
.image-card-container-thrift {
    flex-direction: column;
}

.image-card-thrift-right {
    flex-direction: column;
    border-radius: 0;
    padding: 0%;
}

.image-text-container-thrift-right {
    padding: 10px;
}

.image-card-thrift-right img {
    max-width: 100%;
    height: auto;
    order: -1; 
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0px;
}
.image-container-thrift {
    display: block;
   
}
.image-container-thrift img{
    max-width: 100%;
}

.image-with-overlay-thrift {
    position: relative;
}

.image-with-overlay-thrift img {
    max-width: 100%;
    height: auto;
}
.overlay-thrift {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(190, 40, 96, 0.9), rgba(190, 40, 96, 0.9)), url('../images/card-imageback.png');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff; 
    padding: 10px 0px 10px 0px;
   
}

.overlay-thrift h2 {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
    padding: 0px 0px 0px 0px;
    
}

.overlay-thrift p {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 10px 10px 10px 10px;
    text-align: center;
    line-height: 19.6px;

}
.overlay-learn-more{
    width: 50%; 
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 10px 10px;
    cursor: pointer;
    font-size: 16px;
    margin-left: 20px;
    font-size: 5px;
}

}

:root {
  --var-dark-blue: #222572;
  --var-fade-blue: #7476A6;

  --var-brwnish-prple:#AF3C63;
  --var-pink-prple:#EB81A5;

  --var-blackish: #1d1c1c;

  --var-box-brcle: #DAD9E7;

  --var-white: #ffff;
}

footer {
  display: flex;
  gap: 30px;
  padding: 50px 50px 100px 50px;
  background-color:transparent;
}

.footer-section {
  width: 33%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-socials {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footer-socials img {
  max-width: 15px;
  height: fit-content;
  cursor: pointer;
}

footer .footer-section:nth-of-type(1) {
  width: 50%;
}

.footer-text {
 color: rgba(13, 17, 62, 0.70);
 line-height: 140%;
  font-size: 12px;
  font-weight: 400;
  font-family: 'HumeGeo1', sans-serif;
  font-style: normal;
  

}

.footer-section:nth-of-type(1) .footer-logo{
  width: 50px;
  height: fit-content;
  max-width: 50px;
  max-height: fit-content;
  flex-shrink: 0;
  
}

.footer-section-subheading {
  color: #BE2860;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: 'HumeGeo1', sans-serif;
  line-height: normal;
  
  
}

.footer-link {
  color: rgba(13, 17, 62, 0.80);
  font-weight: 400;
  font-style: normal;
  line-height: 160%;
  font-size: 16px;
  font-family: 'HumeGeo1', sans-serif;
  text-decoration: none;

}



@media (max-width: 950px) {
  /* body {
      background-color: red;
  } */

  footer {
      flex-wrap: wrap;
      gap: 40px;
      padding-left: 20px;
      padding-right: 20px;
  }

  .footer-section {
      width: 20%;
  }

  .footer-section:nth-of-type(4) {
      width: 35%;
  }

}

@media (max-width: 650px) { 
  /* body {
      background-color: green;
  } */
  .footer-section {
      width: 100%;
      min-width: 300px;
  }

  footer .footer-section:nth-of-type(1), 
  .footer-section:nth-of-type(4) {
      width: 100%;
  }
}

